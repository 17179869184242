import * as React from 'react';
import axios from "axios";
import hero from './hero-img.png';
import features1 from "./features-1.png";
import values1 from "./values-1.png";
import values2 from "./values-2.png";
import values3 from "./values-3.png";
import Loader from './Loader';
import './App.css';

function App() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [enquiryResponse, setEnquiryResponse] = React.useState(undefined);

  const onName = (e) => {
    setName(e.target.value);
  };
  const onEmail = (e) => {
    setEmail(e.target.value);
  };
  const onSubject = (e) => {
    setSubject(e.target.value);
  };
  const onMobile = (e) => {
    setMobile(e.target.value);
  };
  const onMessage = (e) => {
    setMessage(e.target.value);
  };

  const onMobileInput = () => {
    // this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');
    const val = mobile.replace(/(\..*)\./g, '$1');
    setMobile(val);
  };

  const onMobileKeyDown = (e) => {
    if (mobile.length == 10 && e.keyCode != 8) return false;
    return true;
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setEnquiryResponse(undefined);

    try {
      const postData = {
        name: name,
        email: email,
        mobile: mobile,
        subject: subject,
        message: message,
        application: 'needo-web',
      };

      // prod -  https://3a8iu7jcti.execute-api.ap-southeast-2.amazonaws.com/master
      // dev -  https://pywwkt57hc.execute-api.ap-southeast-2.amazonaws.com/develop
      const response = await axios.post('https://3a8iu7jcti.execute-api.ap-southeast-2.amazonaws.com/master/contact-us/v1', postData);
      if (response.status === 200) {
        setEnquiryResponse({ isSuccess: true });
        setIsLoading(false);
        setEmail('');
        setName('');
        setMessage('');
        setMobile('');
        setSubject('');
      }

    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setEnquiryResponse({ isSuccess: false });
    }
  }

  return (
    <main id="main">

      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">We offer modern solutions to grow your business and reduce operational costs through Artificial Intelligence</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">We are team of skilled developers creating novel AI solutions for your organization</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Get Started</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src={hero} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

      </section>

      <section id="about" className="about">

        <div className="container" data-aos="fade-up">
          <div className="row gx-0">

            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h3>Who We Are</h3>
                <h2>Using analysis and prediction to drive business growth.</h2>
                <p>
                  We are a start up determined to assist businesses to reach higher potential. Because we see there are massive opportunities
                  to grow businesses using modern tech like machine learning and artificial intelligence. We believe by using most of the
                  existing machine learning and artificial intelligence, businesses can automate some of their workflows to reduce the operational
                  costs, hence driving up the net profit.
                  <br /><br />
                  So SME's, this is your opportunity to work with us and reshape your presence in the market.
                </p>
                <div className="text-center text-lg-start">
                  <a href="#services" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Check Services</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
              <img src={features1} className="img-fluid" alt="" style={{ padding: '0 20px' }} />
            </div>

          </div>
        </div>

      </section>

      <section id="values" className="values">

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <h2>Our Values</h2>
            <p>We love our customers</p>
          </header>

          <div className="row">

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="box">
                <img src={values1} className="img-fluid" alt="" />
                <h3>Customer commitment</h3>
                <p>We commit to what we agree with our customers and deliver. The delivery comes with a proper schedule as per customer.</p>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
              <div className="box">
                <img src={values2} className="img-fluid" alt="" />
                <h3>Innovation</h3>
                <p>We do innovation and let our customers to carry over their business as usual and grow.</p>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
              <div className="box">
                <img src={values3} className="img-fluid" alt="" />
                <h3>Timely delivery</h3>
                <p>Time is not what businesses can compromise with these days. We know that and honor it.</p>
              </div>
            </div>

          </div>

        </div>

      </section>

      <section id="services" className="services">

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <h2>Services</h2>
            <p>Our services cover wide range of AI use cases</p>
          </header>

          <div className="row gy-4">

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200" id="build-ml">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Build machine learning models</h3>
                <p>Machine learning can help you immensely to predict business outcomes. If you can assist us providing historical data, we can
                  build, train and deploy machine learning models for you. So it can be queried to get results.
                </p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300" id="create-bot">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Create bots</h3>
                <p>Bots can help you to automate customer inquiries or even perform business actions on your behalf, hence reducing the operational costs greatly. Whether you are from airline/travel,
                  financial, insurance, retail or telecommunications, we can design, build and integrate bots with your web applications.
                </p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400" id="text-insight">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Text insight</h3>
                <p>We help you to analyze structured or unstructured text which may be in digital or physical form.
                  This can be used to automate or augment your business decision processes.
                  For example automate your expense invoices, analyze lending capability of your customers, etc...</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500" id="text-extract">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Text extraction</h3>
                <p>You have few or many documents with structured or unstructured text, we can help you to extract them to text format
                  and apply other processes on top of the data.</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600" id="text-translate">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Text translation</h3>
                <p>We help you to translate text to/from many language pairs.
                  For example, if you have a document in pdf or image format, we can extract text, translate it and apply other processes on top of the data.</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700" id="object-recognition">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Object recognition</h3>
                <p>Do you want to identify objects in images or videos? Let us help you with that by providing a summarized data of an image or video.</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500" id="text-speech">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Text to speech</h3>
                <p>Get the text you have to be converted to an audio clip with language of your choice (out of supported ones).</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600" id="transcribe">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Transcribe service</h3>
                <p>Get the text from an audio or video clip with language of your choice (out of supported ones).</p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700" id="cloud-apps">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Cloud applications</h3>
                <p>If you want to develop a cloud based web application with the modern offerings, we can help you with that by architecturing and
                  implementing the solution as per your requirement and budget.
                  On the other hand, we also do provide general consultancy services on building/maintaining/migrating web applications.
                </p>
                <a href="#contact" className="read-more"><span>Enquire</span> <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>

          </div>

        </div>

      </section>

      <section id="faq" className="faq">

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>

          <div className="row">
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="faqlist1">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      What is artificial intelligence and machine learning?
                    </button>
                  </h2>
                  <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                      Artificial intelligence is the capability of a computer system to mimic human cognitive functions such as learning and problem-solving. Through AI, a computer system uses math and logic to simulate the reasoning that people use to learn from new information and make decisions.
                      <br /><br />
                      Machine learning is an application of Artificial intelligence. It’s the process of using mathematical models of data to help a computer learn without direct instruction. This enables a computer system to continue learning and improving on its own, based on experience.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                      How does it help your business?
                    </button>
                  </h2>
                  <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                      Artificial intelligence and machine learning enable companies to discover valuable insights in a wider range of structured and unstructured data sources.
                      <br /><br />Companies use machine learning to improve data integrity and use AI to reduce human error—a combination that leads to better decisions based on better data.
                      <br /><br />Also With AI and machine learning, companies become more efficient through process automation, which reduces costs and frees up time and resources for other priorities.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                      Is it a significant investment in an artificial intelligence project?
                    </button>
                  </h2>
                  <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                      The investment is based on what your requirement are. But with the latest cloud capabilities, the capital required to build a AI soultion and subsequent
                      operational costs are reducing by the day.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                      Does Needo build custom cloud solutions?
                    </button>
                  </h2>
                  <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                      Yes. We build and deploy custom cloud solutions other than AI. Talk to us if you need such.
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-6">

              <div className="accordion accordion-flush" id="faqlist2">

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                      Is there a practical ROI in AI?
                    </button>
                  </h2>
                  <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                      Yes definitely. The ROI comes on various factors like making informed and faster business decisions and operational efficiency through automation.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                      How to get started with Needo?
                    </button>
                  </h2>
                  <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                      Please contact us with contact form below on the page and we'll get back to you as soon as possible.
                      <br /><br />Then if you are interested, we can discuss and layout a project idea for your requirement.
                      <br /><br />After that we could build, get the acceptance from you and deploy the project.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                      Does Needo help customers to maintain services that it built?
                    </button>
                  </h2>
                  <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                      Yes. Needo can help businesses to maintain solutions that it built and deployed subjected to a fair charge.
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

      </section>

      <section id="contact" className="contact">

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </header>

          <div className="row gy-4">

            <div className="col-lg-6">

              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>+61478717387</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>info@needo.com.au</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>Monday - Sunday<br />9:00AM - 5:00PM</p>
                  </div>
                </div>
              </div>

            </div>

            <div className="col-lg-6">
              {isLoading && <Loader />}
              {
                enquiryResponse && !enquiryResponse.isSuccess &&
                <div className="alert alert-danger" role="alert">
                  We had a problem when creating the enquiry. Please try again.
                </div>
              }
              {
                enquiryResponse && enquiryResponse.isSuccess &&
                <div className="alert alert-success" role="alert">
                  We have informed our team regarding your enquiry successfully.
                </div>
              }
              <form className="php-email-form" onSubmit={onSubmitForm}>
                <div className="row gy-4">
                  <div className="col-md-6 form-item">
                    <input type="text" name="name" className="form-control" placeholder="Your Name" required maxLength="100" onChange={onName} value={name} /><span style={{ color: 'red' }}>*</span>
                  </div>

                  <div className="col-md-6 form-item">
                    <input type="email" className="form-control" name="email" placeholder="Your Email" required maxLength="100" onChange={onEmail} value={email} /><span style={{ color: 'red' }}>*</span>
                  </div>

                  <div className="col-md-6 form-item">
                    <input type="number" className="form-control" name="mobile" placeholder="Your Mobile"
                      onInput={onMobileInput}
                      onKeyDown={onMobileKeyDown}
                      required onChange={onMobile} value={mobile} /><span style={{ color: 'red' }}>*</span>
                  </div>

                  <div className="col-md-12 form-item">
                    <input type="text" className="form-control" name="subject" placeholder="Subject" required maxLength="100" onChange={onSubject} value={subject} /><span style={{ color: 'red' }}>*</span>
                  </div>

                  <div className="col-md-12 form-item">
                    <textarea className="form-control" name="message" rows="6" placeholder="Message (500 chars)" required maxLength="500" onChange={onMessage} value={message}></textarea><span style={{ color: 'red' }}>*</span>
                  </div>

                  <div className="col-md-12 text-center form-item">
                    <button type="submit" name="submit" className="btn btn-primary" style={{ marginBottom: '20px' }}
                      disabled={!name || !mobile || !subject || !message || !email || isLoading}>Send Message</button>
                  </div>
                </div>
              </form>
            </div>

          </div>

        </div>

      </section>

    </main>
  );
}

export default App;
